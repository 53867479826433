<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
    <div class="master-content">

      <div class="grid-x grid-margin-x">
        <div class="large-auto cell">
          <h2>
            <i class="dx-icon nav-icon fa-light fa-cash-register"></i> 
            {{ title }}
          </h2>
        </div>
      </div>

      <DxPopup
        v-model:visible="receiptPopupVisible"
        :drag-enabled="false"
        :show-close-button="false"
        :show-title="true"
        :width="300"
        :height="150"
        title="Quittung drucken?"
        position="center"
      >

        <div class="grid-x grid-margin-x">
          <div class="auto cell" style="padding: 2px 2px;">
            <button class="cx-button medium" @click="printReceipt(selectedItems, selectedCustomer.firstname, selectedCustomer.lastname)">Ja</button>
          </div>
          <div class="shrink cell" style="padding: 2px 2px;">
            <button class="cx-button medium" @click="receiptPopupVisible = false">Nein</button>
          </div>
        </div>

      </DxPopup>

      <DxPopup
        v-model:visible="deleteTransactionVisible"
        :drag-enabled="false"
        :show-close-button="false"
        :show-title="true"
        :width="300"
        :height="150"
        title="Transaktion löschen?"
        position="center"
      >
         
        <div class="grid-x grid-margin-x">
          <div class="auto cell" style="padding: 2px 2px;">
            <button class="cx-button medium" 
              @click="async () => { 
                await deleteTransaction(); 
                deleteTransactionVisible = false; 
                deleteTransactionId = undefined;
                oldCartGridObj.refresh();
              }"
            >Ja</button>
          </div>
          <div class="shrink cell" style="padding:2px 2px;"> 
            <button class="cx-button medium" @click="() => {deleteTransactionVisible = false; deleteTransactionId = undefined;}">Nein</button>
          </div>
        </div>

      </DxPopup>

      <div class="grid-x grid-margin-x">
        <div class="medium-6 large-9 cell">
          <!-- left -->
          <div class="dx-card -has-paddings">

            <ul id="tabs" class="tabs" data-tabs style="border-top: none; border-left: none; border-right: none;">
              <li class="tabs-title is-active">
                <a href="#panel1d" aria-selected="true">
                  <div class="grid-x align-middle">
                    <div class="shrink cell" style="margin-right: 6px">
                      <i class="dx-icon fa-light fa-tag" style="font-size: 18px"></i>
                    </div>
                    <div class="auto cell">
                      Produkte
                    </div>
                  </div>
                </a>
              </li>
              <li class="tabs-title hide">
                <a href="#panel2d">
                  <div class="grid-x align-middle">
                    <div class="shrink cell" style="margin-right: 6px">
                      <i class="dx-icon fa-light fa-ticket" style="font-size: 18px"></i>
                    </div>
                    <div class="auto cell">
                      Abo's
                    </div>
                  </div>
                </a>
              </li>
              <li class="tabs-title">
                <a href="#panel3d">
                  <div class="grid-x align-middle">
                    <div class="shrink cell" style="margin-right: 6px">
                      <i class="dx-icon fa-light fa-users" style="font-size: 18px"></i>
                    </div>
                    <div class="auto cell">
                      Kunden
                    </div>
                  </div>
                </a>
              </li>
              <li class="tabs-title">
                <a href="#panel4d">
                  <div class="grid-x align-middle">
                    <div class="shrink cell" style="margin-right: 6px">
                      <i class="dx-icon fa-light fa-cash-register" style="font-size: 18px"></i>
                    </div>
                    <div class="auto cell">
                      Einkäufe
                    </div>
                  </div>
                </a>
              </li>
            </ul>

            <div class="tabs-content" data-tabs-content="tabs" style="padding: 0; border: none;">

              <div class="tabs-panel is-active" id="panel1d" style="padding: 0; border: none;">
                <!-- {{ shopItems }} -->
                <!-- div loader -->
                <div :class="shopItems ? 'hide' : ''">
                  <div class="grid-x align-middle">
                    <div class="auto cell">
                    </div>
                    <div class="shrink cell">
                      <div style="padding: 20px;">
                        <DxLoadIndicator
                          id="medium-indicator"
                          :height="40"
                          :width="40"
                        />
                      </div><!-- /div padding -->
                    </div>
                    <div class="auto cell">
                    </div>
                  </div><!-- /grid-x -->
                </div>
                <!-- /div loader -->

                <div :class="!shopItems ? 'hide' : ''">

                  <DxTileView
                    :items="shopItems"
                    :direction="'horizontal'"
                    :base-item-height="120"
                    :base-item-width="185"
                    :item-margin="4"
                  >
                    <template #item="{ data }">
                      <div @click="selectshopitem(data.id, data.Price, data.Subject, data.Sku, data.Picthumb)" style="border: 0px solid pink; padding: 0px; background-color: #f3f3f3;">

                        <div class="grid-x align-middle">
                          <div class="auto cell">
                            <div class="ck-overflow-ellipsis" style="padding: 3px;">
                              <p style="font-size: 12px; font-weight: 600;">{{ data.Subject }}</p>
                            </div>
                          </div>
                          <div class="shrink cell" style="margin-right: 3px;">
                            <div class="trigger-hover"><i class="fa-solid fa-circle-info"></i></div>
                            <div class="target-hover">
                              <div class="ck-overflow-ellipsis" style="padding: 3px;">
                                <p style="font-size: 12px; font-weight: 600;">{{ data.Subject }}</p>
                              </div>
                              <div style="padding: 3px;">
                                {{ data.Description }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="grid-x align-middle" style="background-color: #ffffff;">
                          <div class="shrink cell">
                            <div style="border: 0px solid aqua; width: 50px; height: 50px;">
                              <img :src="data.Picthumb" :alt="data.Subject">
                            </div>
                          </div>
                          <div class="auto cell text-right" style="padding: 0 3px;">
                            <p>CHF {{ formatPrice(data.Price) }}</p>
                          </div>
                        </div>

                        <div class="ck-overflow-ellipsis" style="padding: 0 3px;">
                          <p style="line-height: 1.0;">
                            <small>Art-Nr.: {{ data.Sku }}</small><br />
                            <small>EAN-13: {{ data.Barcode }}</small><br />
                            <small>MWST.: {{ lookUpVatInfo(data.Vat) }}</small><br />
                          </p>
                        </div>


                        <!-- <p><small>Art-Nr.: {{ data.Sku }}</small></p> -->
                        <!-- <p><small>id: {{ data.id }}</small></p> -->
                        <!-- <p><small>{{ data.id }} | {{ data.Barcode }}</small></p> -->

                        <div :class="{ hide: !data.Barcode }" style="width: 177px; height: 50px; overflow: hidden;">
                          <div class="hide">
                            <Vue3Barcode 
                              :id="data.id" 
                              :value="data.Barcode" 
                              :format="'ean13'" 
                              :height="50" 
                            />
                          </div>
                        </div>


                        <!-- {{ data.id }} -->
                        <!-- {{ data.Price }} -->
                        <!-- {{ data.Sku }} -->
                        <!-- {{ data.Barcode }} -->
                        <!-- {{ data.Picthumb }} -->

                      </div>
                    </template>
                  </DxTileView>

                </div>
                <!-- !shopItems ? 'hide' -->

              </div>
              <!-- tabs-panel -->

              <div class="tabs-panel" id="panel2d" style="padding: 0; border: none;">
                <!-- {{ aboItems }} -->
                <!-- div loader -->
                <div :class="aboItems ? 'hide' : ''">
                  <div class="grid-x align-middle">
                    <div class="auto cell">
                    </div>
                    <div class="shrink cell">
                      <div style="padding: 20px;">
                        <DxLoadIndicator
                          id="medium-indicator"
                          :height="40"
                          :width="40"
                        />
                      </div><!-- /div padding -->
                    </div>
                    <div class="auto cell">
                    </div>
                  </div><!-- /grid-x -->
                </div>
                <!-- /div loader -->

                <div :class="!aboItems ? 'hide' : ''">

                  <DxTileView
                    :items="aboItems"
                    :direction="'horizontal'"
                    :base-item-height="120"
                    :base-item-width="185"
                    :item-margin="4"
                  >
                    <template #item="{ data }">
                      <div @click="selectshopitem(data.id, data.Price, data.Subject, data.Sku, data.Picthumb)" style="border: 0px solid pink; padding: 0px; background-color: #f3f3f3;">

                        <div class="grid-x align-middle">
                          <div class="auto cell">
                            <div class="ck-overflow-ellipsis" style="padding: 3px; margin-right: 10px;">
                              <p style="font-size: 12px; font-weight: 600;">{{ data.Subject }}</p>
                            </div>
                          </div>
                          <div class="shrink cell" style="margin-right: 3px;">
                            <i class="fa-solid fa-circle-info"></i>
                          </div>
                        </div>


                        <div class="grid-x align-middle" style="background-color: #ffffff;">
                          <div class="shrink cell">
                            <div style="border: 0px solid aqua; width: 50px; height: 50px;">
                              <img :src="data.Picthumb" :alt="data.Subject">
                            </div>
                          </div>
                          <div class="auto cell text-right" style="padding: 0 3px;">
                            <p>CHF {{ formatPrice(data.Price) }}</p>
                          </div>
                        </div>

                        <div class="ck-overflow-ellipsis" style="padding: 0 3px;">
                          <p style="line-height: 1.0">
                            <small>Art-Nr.: {{ data.Sku }}</small><br />
                            <small>MWST.: {{ lookUpVatInfo(data.Vat) }}</small><br />
                          </p>
                        </div>


                        <!-- <p><small>Art-Nr.: {{ data.Sku }}</small></p> -->
                        <!-- <p><small>id: {{ data.id }}</small></p> -->
                        <!-- <p><small>{{ data.id }} | {{ data.Barcode }}</small></p> -->

                        <div :class="{ hide: !data.Barcode }" style="width: 177px; height: 50px; overflow: hidden;">
                          <div class="hide">
                            <Vue3Barcode 
                              :id="data.id" 
                              :value="data.Barcode" 
                              :format="'ean13'" 
                              :height="50" 
                            />
                          </div>
                        </div>


                        <!-- {{ data.id }} -->
                        <!-- {{ data.Price }} -->
                        <!-- {{ data.Sku }} -->
                        <!-- {{ data.Barcode }} -->
                        <!-- {{ data.Picthumb }} -->

                      </div>
                    </template>
                  </DxTileView>

                </div>
                <!-- !shopItems ? 'hide' -->

              </div>
              <!-- tabs-panel -->

              <div class="tabs-panel" id="panel3d" style="padding: 0; border: none;">

                <!-- {{ customerItems }} -->
                <!-- div loader -->
                <div :class="customerItems ? 'hide' : ''">
                  <div class="grid-x align-middle">
                    <div class="auto cell">
                    </div>
                    <div class="shrink cell">
                      <div style="padding: 20px;">
                        <DxLoadIndicator
                          id="medium-indicator"
                          :height="40"
                          :width="40"
                        />
                      </div><!-- /div padding -->
                    </div>
                    <div class="auto cell">
                    </div>
                  </div><!-- /grid-x -->
                </div>
                <!-- /div loader -->

                <div :class="!customerItems ? 'hide' : ''">

                  <DxList
                    :data-source="customerItems"
                    :height="400"
                    :search-enabled="true"
                    :search-mode="'contains'"
                    search-expr="Search"
                  >
                    <template #item="{ data }">

                      <div @click="selectcustomeritem(data.id, data.FirstName, data.LastName, data.Rfid, data.Credit, data.Avatar)" style="padding: 0 5px;">

                        <div class="grid-x align-middle">

                          <div class="shrink cell">
                            <!-- avatar -->
                            <div class="user-thumb" 
                              style="margin: 2px 10px 2px 0;"
                              v-bind:style="{ 'background-image': 'url(' + data.Avatar  + ')' }" >
                            </div><!-- /avatar -->
                          </div><!-- /shrink cell main -->

                          <div class="auto cell">
                            <div class="grid-x align-middle">

                              <div class="shrink cell">
                                <!-- row 1 -->
                                <div class="fixedwidth-listitem">
                                  <p class="ck-overflow-ellipsis strong small">
                                    <span>{{ data.FirstName }} {{ data.LastName }}</span>
                                  </p>
                                  <p class="ck-overflow-ellipsis small"> 
                                    Rfid: {{ data.Rfid }}
                                  </p>
                                </div><!-- /fixedwidth-listitem -->
                              </div><!-- /shrink cell -->

                              <div class="shrink cell">
                                <!-- row 2 -->
                                <div class="fixedwidth-listitem">
                                  <p class="ck-overflow-ellipsis strong small">
                                    Kredit
                                  </p>
                                  <p class="ck-overflow-ellipsis -strong small">
                                    CHF {{ formatPrice(data.Credit) }}
                                  </p>
                                </div><!-- /fixedwidth-listitem -->
                              </div><!-- /shrink cell -->

                            </div><!-- /grid-x sub-->

                          </div><!-- /auto cell main -->
                        </div><!-- /grid-x main -->

                      </div><!-- /click -->

                    </template>
                  </DxList>

                </div>
                <!-- !shopItems ? 'hide' -->

              </div>
              <!-- tabs-panel -->


              <div class="tabs-panel" id="panel4d" style="padding: 0; border: none;">


                <div v-if="isDataLoaded" class=""> <!-- dx-card shoppingcart list -->

                  <!-- <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p style="font-size: 12px;">Alte Einkäufe</p>
                    </div>
                  </div> -->

                  <div class="grid-x align-middle" style="min-height: 60px">
                    <div class="large-5 cell" style="margin-left:10px;margin-right:5px">
                      <DxTagBox
                        :items="['Kasse', 'Vending', 'Datum', 'Gelöscht']"
                        :show-selection-controls="true"
                        v-model:value="filterArray"
                        apply-value-mode="useButtons"
                        :multiline="false"
                        placeholder="Filter..."
                        label="Filter"
                        label-mode="outside"
                        @value-changed="() => { oldCartGridObj.refresh(); }"
                      />
                    </div>
                    <div class="large-5 cell" style="margin-right: 10px;">
                      <DxDateBox
                        v-model:value="oldTransactionDate"  
                        display-format="dd.MM.yyyy"
                        type="date"
                        apply-value-mode="useButtons"
                        @value-changed="() => { oldCartGridObj.refresh(); }"
                      />
                    </div>
                    <div class="auto cell"></div>
                    <div class="shrink cell">
                      <a class="fa-duotone fa-solid fa-arrows-rotate fa-xl" style="margin-right: 10px;"
                        @click="() => { oldCartGridObj.refresh(); }"></a>
                    </div>
                  </div>

                  <DxDataGrid
                    :data-source="shoppingcartListDataSource"
                    :remote-operations="true"
                    :show-column-headers="false"
                    style="height:200px;display:block"
                    @initialized="(e) => { oldCartGridObj = e.component; }"
                  >
                    <DxScrolling 
                      mode="infinite" 
                      row-rendering-mode="virtual"
                    />
                    <DxPaging 
                      :enabled="true"
                      :page-size="20" 
                    />

                    <DxColumn
                      alignment="left"
                      cell-template="subjectTemplate"
                    />

                    <DxMasterDetail
                      :enabled="true"
                      template="masterDetailTemplate"
                    />

                    <template #subjectTemplate="{ data: content }">
                      <div class="grid-x -grid-margin-x align-middle ck-itemrow" style="padding: 0px 5px;">
                        <div class="shrink cell">
                          <div class="user-thumb" 
                            style="margin-right: 15px;"
                            v-bind:style="{ 'background-image': 'url(' + lookUpCustomerInfo(content.data.customerId).Avatar + ')' }" 
                            >
                          </div>
                        </div>
                        <div class="auto cell">
                          <p class="ck-overflow-ellipsis strong">
                            <span :style="{'margin-right': '3px', 'color': content.data.deleted ? 'red' : '', 'text-decoration': content.data.deleted ? 'line-through' : ''}">
                              {{ lookUpCustomerInfo(content.data.customerId).FirstName }} {{ lookUpCustomerInfo(content.data.customerId).LastName }} | 
                            </span>
                            
                            <span :style="{'color': content.data.deleted ? 'red' : 'gray', 'text-decoration': content.data.deleted ? 'line-through': ''}" 
                              :class="getPaymentMethodClass(content.data.method)" :title="content.data.method == 'vend' ? 'Vending' : 'Kasse'"></span>
                          </p>
                          <p class="ck-overflow-ellipsis small">
                            <span>{{ unixToString(content.data.timestamp) }}</span>
                          </p>
                        </div>
                        <div class="shrink cell">
                          <p class="ck-overflow-ellipsis strong">
                            <span>Total: CHF {{ content.data.total }}</span>
                          </p>
                        </div>
                        <div class="shrink cell">
                          <a class="fa-duotone fa-solid fa-trash" style="margin-left:5px;" 
                            v-if="!content.data.deleted" title="Löschen"
                            @click="() => {deleteTransactionVisible = true; deleteTransactionId = content.data.id;}"></a>
                          <a class="fa-solid fa-arrows-rotate" style="margin-left:5px;"
                            v-else 
                            @click="async () => { deleteTransactionId = content.data.id; await deleteTransaction(true); oldCartGridObj.refresh(); }">
                          </a>
                        </div>
                        <div class="shrink cell">
                          <a class="fa-light fa-print" style="margin-left:10px;" 
                            @click="printReceipt(JSON.parse(content.data.products), 
                              lookUpCustomerInfo(content.data.customerId).FirstName, 
                              lookUpCustomerInfo(content.data.customerId).LastName)"></a>
                        </div>
                      </div>
                    </template>
                    
                    <template #masterDetailTemplate="{ data: content }">
                      <div v-for="[id, item] in formatShoppingCart(content.data.products)" :key="id">
                        <div class="grid-x align-middle ck-itemrow" style="border-bottom: 1px solid #dedede;">
                          <div class="shrink cell" style="margin: 0px 10px 0px 10px;">
                            <p class="ck-overflow-ellipsis strong">{{ item.amount }}x</p>
                          </div>
                          <div class="shrink cell">
                            <div class="user-thumb" style="margin: 0px 10px 0px 5px;"
                              v-bind:style="{ 'background-image': 'url(' + item.product.pic  + ')' }" >
                            </div>
                          </div>
                          <div class="auto cell grid-x">
                            <div class="auto cell">
                              <p class="ck-overflow-ellipsis small">{{ item.product.subject }}</p>
                              <p class="ck-overflow-ellipsis small">CHF {{ formatPrice(item.product.price) }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                  </DxDataGrid>
                </div>







              </div>
              <!-- tabs-panel -->


            </div>
            <!-- tabs-content -->

          </div>

        </div>
        <div class="medium-6 large-3 cell">
          <!-- right -->
          <div class="dx-card -has-paddings">
            <!-- card -->

            <!-- CUSTOMER -->
            <div class="dx-card-label grid-x align-middle">
              <div class="auto cell">
                <p style="font-size: 12px;">Kunde</p>
              </div>
            </div>

            <div class="grid-x -grid-margin-x align-middle ck-itemrow" style="padding: 0px 5px;">
              <div class="shrink cell">
                <!-- avatar -->
                <div class="user-thumb" 
                  style="margin-right: 15px;"
                  v-bind:style="{ 'background-image': 'url(' + selectedCustomer.avatar  + ')' }" >
                </div>
                <!-- /avatar -->
              </div><!-- /shrink cell -->
              <div class="auto cell">
                <p class="ck-overflow-ellipsis strong">
                  <span>{{ selectedCustomer.firstname }} {{ selectedCustomer.lastname }}</span></p>
                <p v-if="selectedCustomer.id > 0" class="ck-overflow-ellipsis small">
                  Id: {{ selectedCustomer.rfid }} | CHF {{ formatPrice(selectedCustomer.credit) }}
                </p>
              </div><!-- /auto cell -->
              <div v-if="selectedCustomer.id > 0" class="shrink cell">
                <a @click="deletecustomeritem()" class="-hide" style="padding-right: 5px;">
                  <i class="fa-light fa-trash"></i>&nbsp; 
                </a>
              </div><!-- /shrink cell -->

            </div><!-- /ck-itemrow -->


            <ul :id="'accordionCredit'" class="accordion is-label" :class="selectedCustomer.id == 0  ? 'hide' : ''" data-accordion>

              <li :id="'accItemCredit1'" class="accordion-item -hide" data-accordion-item>
                <a href="#" class="accordion-title has-border-top">
                  <div class="grid-x align-middle" style="height: 100%;">
                    <div class="cell">
                      Guthaben laden
                    </div>
                  </div>
                </a>
                <div class="accordion-content" data-tab-content style="border-bottom: 1px solid #dedede">
                  <div class="grid-x align-middle">
                    <div class="shrink cell" style="width: 150px;">
                      <input class="dx-texteditor-input ck-input" style="padding: 15px" type="number" :id="'Amount'" v-model="selectedCredit">
                    </div>
                    <div class="auto cell">

                    </div>
                    <div class="shrink cell">
                      <a @click="() => { if(selectedCredit > 0 || selectedCredit < 0) { selectshopitem(0, selectedCredit, 'Guthaben laden', '', creditThumbnail) }}" class="-hide" style="padding-right: 10px;">
                        <i class="fa-light fa-money-bill-transfer"></i>
                      </a>
                    </div>
                  </div>
                </div>

              </li>

              <li class="accordion-item" data-accordion-item>
                <a href="#" class="accordion-title has-border-top">
                  <div class="grid-x align-middle" style="height: 100%;">
                    <div class="cell">
                      Raten
                    </div>
                  </div>
                </a>
                <div class="accordion-content" data-tab-content style="border-bottom: 1px solid #dedede">
                  <DxDataGrid
                    :data-source="rateMasterData"
                    :show-column-headers="false"
                    no-data-text="Dieser Benutzer hat keine offene Raten."
                    @initialized="(e) => { rateGridObj = e.component }"
                  >
                    <DxScrolling 
                      mode="infinite" 
                      row-rendering-mode="virtual"
                    />

                    <DxColumn
                      alignment="left"
                      cell-template="subjectTemplate"
                    />

                    <DxMasterDetail
                      :enabled="true"
                      template="masterDetailTemplate"
                    />

                    <template #subjectTemplate="{ data: content }">
                      <div class="grid-x -grid-margin-x align-middle ck-itemrow" style="padding: 0px 5px;">
                        <div class="shrink cell">
                          <div class="user-thumb" 
                            style="margin-right: 15px;"
                            v-bind:style="{ 'background-image': 'url(' + aboMap.get(content.data.aboId).Picthumb + ')' }" >
                          </div>
                        </div>
                        <div class="auto cell">
                          <p class="ck-overflow-ellipsis strong">{{ content.data.aboSubject }}</p>
                          <p class="ck-overflow-ellipsis">{{ content.data.rates.length }} offene Raten</p>
                        </div>
                      </div> 
                    </template>
                    
                    <template #masterDetailTemplate="{ data: content }">
                      <div class="grid-x -grid-margin-x align-middle row-rates legend">
                        <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                        </div>
                        <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                          <div class="ck-overflow-ellipsis"><p>Betrag</p></div>
                        </div>
                        <div class="shrink cell" style="width: 120px; margin-right: 5px;">
                          <div class="ck-overflow-ellipsis"><p>Fällig</p></div>
                        </div>
                      </div>

                      <div v-for="rate in content.data.rates" :key="rate.id">
                        <div class="grid-x align-middle row-rates">
                          <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                            <p class="summary-label auto-width">{{ rate.id }}</p>
                          </div>
                          <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                            <div v-if="rate.Price" class="ck-overflow-ellipsis hide">CHF {{ formatPrice(rate.Price) }}</div>
                            <DxNumberBox
                              :value="Math.round(rate.Price * 100) / 100"
                              :input-attr="{ 'aria-label': 'Price' }"
                              :read-only="true"
                              height="24"
                            />
                          </div>
                          <div class="shrink cell" style="width: 120px; margin-right: 5px;">
                            <div v-if="rate.Date" class="grid-x align-middle">
                              <div class="shrink cell" style="margin-right: 5px;">
                                <span v-if="functionStatus(rate.Date,rate.Valuta) == 1" class="-grey-color">
                                  <i class="fa-light fa-hourglass-clock"></i>
                                </span>
                                <span v-if="functionStatus(rate.Date,rate.Valuta) == 2" class="warning-color">
                                  <i class="fa-light fa-triangle-exclamation"></i>
                                </span>
                                <span v-if="functionStatus(rate.Date,rate.Valuta) == 3" class="alert-color">
                                  <i class="fa-light fa-triangle-exclamation"></i>
                                </span>
                                <span v-if="functionStatus(rate.Date,rate.Valuta) == 4" class="success-color">
                                  <i class="fa-light fa-light fa-circle-check"></i>
                                </span>
                              </div>
                              <div class="auto cell">
                                <DxDateBox
                                  :value="rate.Date"
                                  :input-attr="{ 'aria-label': 'Date' }"
                                  :read-only="true"
                                  displayFormat="dd.MM.yyyy"  
                                  type="date"
                                  height="24"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="auto cell">
                          </div>
                          <div class="shrink cell text-right" v-if="functionStatus(rate.Date,rate.Valuta) !== 4">
                            <div style="display: inline-block; margin-left: 10px;">
                              <a @click="() => 
                                { 
                                  selectedRates.set(selectedItems.length, { rateId: rate.id, invoiceId: content.data.id }); 
                                  selectshopitem(content.data.aboId, rate.Price, content.data.aboSubject, '', aboMap.get(content.data.aboId).Picthumb);
                                }
                              ">
                                <i class="fa-light fa-cash-register"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                  </DxDataGrid>

                </div>
              </li>

            </ul>
            <!-- /CUSTOMER -->

            <!-- SHOP ITEMS -->

            <div class="dx-card-label -has-border-top grid-x align-middle" :class="selectedCustomer.id == 0  ? 'has-border-top' : ''">
              <div class="auto cell">
                <p style="font-size: 12px;">Artikel</p>
              </div>
            </div>

            <div v-for="item, index in selectedItems" :key="item.id">

              <div class="grid-x align-middle ck-itemrow" style="border-bottom: 1px solid #dedede;">
                <div class="shrink cell">
                  <div class="user-thumb" style="margin: 0px 10px 0px 5px;"
                    v-bind:style="{ 'background-image': 'url(' + item.pic  + ')' }" >
                  </div>
                </div>
                <div class="auto cell grid-x">
                  <div class="auto cell">
                    <p class="ck-overflow-ellipsis small">{{ item.subject }}</p>
                    <p class="ck-overflow-ellipsis small">CHF {{ formatPrice(item.price) }}</p>
                  </div>
                  <div class="shrink cell">
                    <a @click="removeshopitem(index)" class="-hide" style="padding-right: 9px;">
                      <i class="fa-light fa-trash"></i>&nbsp; 
                    </a>
                  </div>
                </div>
              </div>

              <!-- <p>{{ item.id }}</p>
<p>{{ item.price }}</p>
<p>{{ item.subject }}</p>
<p>{{ item.sku }}</p>
<p>{{ item.pic }}</p> -->


            </div><!-- v-for-->

            <!-- /SHOP ITEMS -->

            <!-- RECEIPT -->

            <div class="dx-card-label grid-x align-middle">
            <div class="auto cell">
              <p style="font-size: 12px;">Total</p>
            </div>
            <div class="shrink cell">
              <a @click="removeall" class="-hide" style="padding-right: 0px;">
                <i class="fa-light fa-trash"></i>&nbsp; 
              </a>
            </div>
          </div>

            <div class="grid-x align-middle" v-if="creditRedeemValue > 0">
              <div class="auto cell" style="padding: 0px 10px">
                <p>Warenkorb</p>
              </div>
              <div class="shrink cell" style="padding: 5px 0px">
                <div style="padding: 0 10px; border: 0px solid orange;">
                  <p>CHF {{ formatPrice(selectedSum) }}</p>
                </div>
              </div>
            </div>

            <div class="grid-x align-middle" v-if="creditRedeemValue > 0">
              <div class="auto cell" style="padding: 0px 10px">
                <p>Guthaben eingelöst</p>
              </div>
              <div class="shrink cell" style="padding: 5px 10px">
                <p>- CHF {{ formatPrice(creditRedeemValue) }}</p>
              </div>
            </div>

            <div class="grid-x align-middle" style="font-size: 14px; font-weight: bold">
              <div class="auto cell" style="padding: 0px 10px">
                <p>Total</p>
              </div>
              <div class="shrink cell" style="padding: 5px 10px">
                <p>CHF {{ formatPrice(selectedSum - creditRedeemValue) }}</p>
              </div>
            </div>
            <!-- /RECEIPT -->

          </div><!-- dx-card-->

          <div class="dx-card" v-if="locationLoaded">
            <div class="dx-card-label grid-x align-middle">
              <p style="font-size: 12px">Location</p>
            </div>
            <div class="grid-x">
              <div class="auto cell">
                <DxSelectBox
                  :data-source="locationList"
                  v-model:value="selectedLocation"
                  display-expr="locationname"
                  value-expr="id"

                  @value-changed="saveLocation"
                />
              </div>
            </div>
          </div>

          <!-- point of sale -->
          <div class="dx-card"> <!-- dx-card cash drawer buttons -->
            <div v-if="paymentMethod === ''">
              <div class="dx-card-label grid-x align-middle">
                <p style="font-size: 12px">Kasse</p>
              </div>
              <div class="grid-x">
                <div class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="paymentMethod='cash'">Bar Zahlung</button>
                </div>
                <div class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="paymentMethod='card'">Karten Zahlung</button>
                </div>
                <div class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="paymentMethod='journal'">Abschluss</button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="dx-card-label grid-x align-middle">
                <p v-if="paymentMethod === 'cash'" style="font-size: 12px">Bar Zahlung</p>
                <p v-else-if="paymentMethod === 'card'" style="font-size: 12px">Karten Zahlung</p>
                <p v-else-if="paymentMethod === 'journal'" style="font-size: 12px">Tagesabschluss</p>
              </div>
              <div class="grid-x">
                <div v-if="paymentMethod !== 'journal'" class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="confirmPayment">Zahlung Abschliessen</button>
                </div>
                <div v-if="paymentMethod === 'journal'" class="shrink cell" style="padding: 5px 5px;">
                  <DxDateBox
                    v-model:value="journalDate"
                    display-format="dd.MM.yyyy"
                    type="date"
                    apply-value-mode="useButtons"
                  />
                </div>
                <div v-if="paymentMethod === 'cash'" class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="openDrawer">Kasse öffnen</button>
                </div>
                <div v-if="paymentMethod !== 'journal'" class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="printReceipt(selectedItems, selectedCustomer.firstname, selectedCustomer.lastname)">Quittung Drucken</button>
                </div>
                <div v-if="paymentMethod === 'journal'" class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="getDailyReport">Herunterladen</button>
                </div>
                <div class="shrink cell" style="padding: 5px 5px;">
                  <button class="cx-button tiny" @click="paymentMethod = ''">Abbrechen</button>
                </div>
              </div>
            </div>
            <div v-if="paymentMethod !== 'journal'" class="grid-x align-middle">
              <div class="auto cell" style="padding: 5px 10px">
                <input class="dx-texteditor-input ck-input" style="padding: 15px" type="number" id='Redeem' value=0 
                  min=0 :max="selectedSum" @change="(e) => { e.target.value = Math.min(selectedSum, Math.max(0, e.target.value))}">
              </div>
              <div class="shrink cell" style="padding: 0 10px;">
                <button class="cx-button tiny" @click="redeemCredit">Guthaben einlösen</button>
              </div>
            </div>
            <div v-if="paymentInProcess" style="padding: 0px 47.5%">
              <DxLoadIndicator/>
            </div>
          </div>

          <div class="dx-card"> <!-- dx-card device connection -->
            <div class="dx-card-label grid-x align-middle">
              <div class="auto cell">
                <p style="font-size: 12px;">Beleg Drucker</p>
              </div>
              <div v-if="printerDevice == undefined" class="shrink cell">
                <p style="font-size: 12px; color: red">Nicht Verbunden</p>
              </div>
              <div v-if="printerDevice != undefined" class="shrink cell" style="padding: 0px 5px">
                <button class="cx-button small" @click="resetPrinter">Reset</button>
              </div>
              <div v-if="printerDevice != undefined" class="shrink cell">
                <p style="font-size: 12px; color: green">Verbunden</p>
              </div>
            </div>

            <div v-if="printerDevice === undefined">
              <div class="grid-x align-middle"> <!-- no device connected -->
                <div class="auto cell" style="padding: 5px 10px;">
                  <DxSelectBox
                    :items="['USB', 'Serial', 'Network']"
                    :input-attr="{ 'aria-label': 'Device Type'}"
                    v-model:value="selectedType"
                  />
                </div>

                <div class="shrink cell" style="padding: 5px 10px;">

                  <div v-if="selectedType === 'USB'"> <!-- USB connection -->
                    <button class="cx-button tiny" @click="usbPair">USB Verbinden</button>
                  </div>

                  <div v-if="selectedType === 'Serial'"> <!-- Serial connection -->
                    <button class="cx-button tiny" @click="serialPair">Serial Verbinden</button>
                  </div>

                  <div v-if="selectedType === 'Network'"> <!-- Network connection -->
                    <button class="cx-button tiny" @click="networkPair">Netzwerk Verbinden</button>
                  </div>

                </div>
              </div>

              <div v-if="selectedType === 'Network'" class="grid-x align-middle"> <!-- network input -->
                <div class="large-9 cell" style="padding: 0px 10px;">Ip address:</div>
                <div class="large-3 cell" style="padding: 0px 10px;">Port:</div>
                <div class="large-9 cell" style="padding: 5px 10px;">
                  <div class="grid-x align-middle">
                    <div class="large-2 cell" style="">
                      <DxNumberBox
                        v-model:value="ipAddress[0]"
                        :min="0"
                        :max="255"
                        :input-attr="{ 'aria-label': 'ip-address-0' }"
                      />
                    </div>
                    <div class="large-1 cell" style="text-align: center">.</div>
                    <div class="large-2 cell" style="">
                      <DxNumberBox
                        v-model:value="ipAddress[1]"
                        :min="0"
                        :max="255"
                        :input-attr="{ 'aria-label': 'ip-address-1' }"
                      />
                    </div>
                    <div class="large-1 cell" style="text-align: center">.</div>
                    <div class="large-2 cell" style="">
                      <DxNumberBox
                        v-model:value="ipAddress[2]"
                        :min="0"
                        :max="255"
                        :input-attr="{ 'aria-label': 'ip-address-2' }"
                      />
                    </div>
                    <div class="large-1 cell" style="text-align: center">.</div>
                    <div class="large-2 cell" style="">
                      <DxNumberBox
                        v-model:value="ipAddress[3]"
                        :min="0"
                        :max="255"
                        :input-attr="{ 'aria-label': 'ip-address-3' }"
                      />
                    </div>
                  </div>
                </div>
                <div class="auto cell" style="padding: 0px 10px">
                  <DxNumberBox
                    v-model:value="tcpPort"
                    :min="0"
                    :input-attr="{ 'aria-label': 'port'}"
                  />
                </div>
              </div> <!-- network input -->

            </div>

          </div> <!-- dx-card pair printer -->

        </div><!-- large-3-->
      </div><!-- grid-x-->
    </div><!-- master-content-->

  </div><!-- content-block-->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

</template>

<script>

import $ from 'jquery';
import Foundation from 'foundation-sites';
import { ref } from 'vue';

import { DxPopup } from 'devextreme-vue/popup';
import DxTileView from 'devextreme-vue/tile-view';
import DxDataGrid, { DxPaging, DxColumn, DxScrolling, DxMasterDetail } from 'devextreme-vue/data-grid';
import DxList from 'devextreme-vue/list';
import notify from 'devextreme/ui/notify';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator'; 
import DxDateBox from 'devextreme-vue/date-box';
import DxTagBox from 'devextreme-vue/tag-box';

import CustomStore from 'devextreme/data/custom_store';

import Vue3Barcode from 'vue3-barcode';

import { jsPDF } from "jspdf";

import auth from "../auth";

import { 
  apihost, 
} from "../api";

let currentUser;
let timestamp;

let shopItems;
let aboItems;
let customerItems;

let selectedItems = [];
let selectedSum = 0;
let selectedRates = new Map();

const guestCustomer = {id: 0, FirstName: "Gast", LastName: "", rfid: "", credit: "", Avatar: apihost+"/static/assets/img/default-avatar.png"};
let selectedCustomer = {id: 0, firstname: "Gast", lastname: "", rfid: "", credit: "", avatar: apihost+"/static/assets/img/default-avatar.png"};
let selectedCredit = ref(0);

let printerDevice = ref(undefined);
let selectedType = ref("USB");

let ipAddress = [0, 0, 0, 0];
let tcpPort = 9100;

let paymentMethod = ref('');

let creditRedeemValue = ref(0);

let paymentInProcess = ref(false);

const websocketPort = 53111;

const creditThumbnail = "https://apidynamic.mysportapp.ch/picabo/989/image-thumb__989__300x300/4332.df3d9bbb.png";

let vatList = [];
let locationList = ref([]);
let selectedLocation = ref(undefined);
let selectedLocationObj = undefined;
let globals = undefined;

let productMap = new Map();
let aboMap = new Map();
let customerMap = new Map();
let invoiceMap = new Map();

let journalDate = ref(new Date());
let oldTransactionDate = ref(new Date());

let receiptPopupVisible = ref(false);
let deleteTransactionVisible = ref(false);
let deleteTransactionId = ref(undefined);

let loaded = [false, false, false, false];
let isDataLoaded = ref(false);
let locationLoaded = ref(false);

let rateGridObj = null;
let oldCartGridObj = null;

let rateMasterData = [];

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

let filterArray = ref(["Kasse", "Vending"]);

const shoppingcartListDataSource = new CustomStore({
  key: "id",
  load: (opts) => {
    let params = "?";
    let filterDeletedParam = filterArray.value.includes("Gelöscht") ? '&del=true' : '';
    
    if(!filterArray.value.includes("Datum")) {
      [
        'take',
        'skip'
      ].forEach(i => {
          if(i in opts && isNotEmpty(opts[i])){
            params += `${i}=${JSON.stringify(opts[i])}&`;
          }
        });
      params = params.slice(0, -1);
      params = params + `&location=${selectedLocation.value}`;
    } else {
      params = `?date=${oldTransactionDate.value.getTime() / 1000}&interval=1`;
    }
    params = params + filterDeletedParam;

    const dataFilter = (item) => {
      if(!filterArray.value.includes("Kasse") && (item.method === "card" || item.method == "cash")) { return false; }
      if(!filterArray.value.includes("Vending") && item.method === "vend") { return false; }
      
      return true;
    };

    return fetch(`${apihost}/vue/shoppingcart/list/${params}`)
      .then(r => r.json())
      .then(o => {
        const filtered = o.filter(dataFilter);
        return {
          data: filtered,
          totalCount: o.length,
          summary: []
        };
      })
      .catch(() => { throw 'Network error'; });
  }
});

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}
async function connectUsb(device) {
  await device.open();

  // select configuration to use
  try {
    const configValue = device.configurations[0].configurationValue;
    await device.selectConfiguration(configValue);
  } catch (error) {
    console.log("This device has no configurations.");
    throw new Error();
  }

  // choose and claim interface
  let alternate = undefined;
  let interfaceNum = undefined;
  try {
    const interfaces = device.configuration.interfaces;
    let interfaceNumber = undefined; 
    let alternateNumber = undefined;
    for (const i of interfaces) {
      for (const a of i.alternates) {
        if (a.interfaceClass == 0x07) { 
          alternate = a;
          alternateNumber = a.alternateSetting; 
          break;
        }
      }

      if (alternateNumber != undefined) {
        interfaceNumber = i.interfaceNumber;
        break;
      }
    }

    if (interfaceNumber == undefined) {
      throw new Error();
    }

    await device.claimInterface(interfaceNumber);
    //await device.selectAlternateInterface(interfaceNumber, alternateNumber);
    interfaceNum = interfaceNumber;
  } catch (e) {
    console.log("Failed to process or claim the interface of the device: " + e.toString())
    throw new Error();
  }

  // choose endpoint and send data
  try {
    let endpointNumber = undefined;
    for (const e of alternate.endpoints) {
      if (e.direction == "out") {
        endpointNumber = e.endpointNumber;
        break;
      }
    }

    if(endpointNumber == undefined) {
      throw new Error();
    }

    return { endpoint: endpointNumber, interface: interfaceNum };
  } catch (error) {
    console.log("Couldn't find endpoint");
    throw new Error();
  }
}

async function connectNetwork(host, port, websocketPort) {
  const clientSocket = new WebSocket(`ws://localhost:${websocketPort}`);
  const UPPERBOUND = 3000;
  let time = 0;
  let testDone = false;
  let testRes = false;

  while(clientSocket.readyState == 0) {
    if(time > UPPERBOUND) {
      throw new Error("Websocket client timed out"); 
    }

    await new Promise(r => setTimeout(r, 100)); // wait for 100s
    time += 100;
  }

  if(clientSocket.readyState != 1){
    throw new Error("Couldn't connect to the websocket server.");
  }
  
  // test connection to printer
  clientSocket.addEventListener("message", (event) => {
    const responseObject = JSON.parse(event.data);
    
    if(responseObject.type == "test") {
      testDone = true; 
      testRes = (responseObject.status == "OK");
    }
  });

  const sendData = {
    type: "test",
    host: host,
    port: port,
  };

  clientSocket.send(JSON.stringify(sendData));
  
  time = 0;
  while(!testDone) {
    await new Promise(r => setTimeout(r, 100));

    time += 100;
    if(time > UPPERBOUND) {
      throw new Error("Test timed out.")
    }
  }
  
  if(!testRes) {
    throw new Error("Connection test with printer failed.");
  } 

  return clientSocket;
}

async function connectAndSendUSB(device, data) {
  try{
    const deviceData = await connectUsb(device);
    await device.transferOut(deviceData.endpoint, data);
    await device.releaseInteface(deviceData.interface);
    await device.close();
  } catch (e) {
    console.log("Couldn't find endpoint or failed to send data.");
    return;
  }

}

async function connectAndSendSerial(port, data) {
  try {
    await port.open({ baudRate: 38400 });
    const writer = port.writable.getWriter();
    await writer.write(data);
    writer.releaseLock();
    await port.close();
  } catch(error) {
    console.log("Couldn't send data: " + error.toString());
    return;
  }

}

async function connectAndSendNetwork(host, port, websocketPort, data) {
  try {
    const clientSocket = await connectNetwork(host, port, websocketPort);
    const dataArray = Array.from(data);

    const sendObject = {
      type: "send",
      host: host,
      port: port,
      data: dataArray,
    };

    clientSocket.send(JSON.stringify(sendObject));
    clientSocket.close();
  } catch (error) {
    console.log("Couldn't connect to websocket: " + error.toString());
    return;
  }
}

function shoppingCartToHex(items) {
  // create map from item to count in cart and total price
  let cartMap = new Map();

  for(const item of items) {
    const itemData = cartMap.get(item.subject);
    if(itemData === undefined){
      cartMap.set(item.subject, {count: 1, price: item.price});
    } else {
      cartMap.set(item.subject, {count: itemData.count + 1, price: item.price});
    } 
  }

  // helper functions for later
  const move = (low, high) => new Uint8Array([0x1B, 0x24, low, high]);
  const longLine = (n) => new Uint8Array(n + 1).fill(0xC4, 0, n).fill(0x0A, n, n + 1); // line that is n units long 
  const formatPrice = (value) => { 
    let val = (value/1).toFixed(2).replace('.', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  };

  // data for every item in shopping cart
  const encoder = new TextEncoder();
  let itemLines = [];
  let sum = 0;
  cartMap.forEach((itemData, itemName) => {
    const itemLine = `${itemData.count}x ${itemName}`.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    
    const price = formatPrice(itemData.count * itemData.price);
    sum += itemData.count * itemData.price;

    itemLines.push(encoder.encode(itemLine));
    itemLines.push(move(0xC0, 0x01));
    itemLines.push(encoder.encode(price + "\n"));
  });

  // data for total sum
  itemLines.push(longLine(48));
  itemLines.push(encoder.encode("Summe"));
  itemLines.push(move(0x20, 0x01));
  itemLines.push(encoder.encode("CHF"));
  itemLines.push(move(0xC0, 0x01));
  itemLines.push(encoder.encode(formatPrice(sum) + "\n"));

  return itemLines;
}

// all ESC Command codes were taken from: https://escpos.readthedocs.io/en/latest/commands.html
function getReceiptDataArray(items, firstname, lastname) {
  let escCommands = [];
  const initialize = new Uint8Array([0x1B, 0x40]); // initialize command.  
  const feed = (n) => new Uint8Array([0x1B, 0x64, n]); // feed n lines 
  const cut = new Uint8Array([0x1B, 0x6D]); // full cut
  const move = (low, high) => new Uint8Array([0x1B, 0x24, low, high]); // move by low + 256 * high
  const lineSpacing = (n) => new Uint8Array([0x1B, 0x33, n]); // set line spacing by n
  const longLine = (n) => new Uint8Array(n + 1).fill(0xC4, 0, n).fill(0x0A, n, n + 1); // line that is n units long 
  const justify = (n) => new Uint8Array([0x1B, 0x61, n]); // line justification. n = 0 (left), n = 1 (center), n = 2 (right)

  const datetimeLabel = "Datum/Zeit";
  const receiptText = "Beleg\n";
  
  const now = new Date();
  const datetimeText = `${String(now.getDate()).padStart(2, 0)}.${String(now.getMonth() + 1).padStart(2, 0)}.${now.getFullYear()} ` +
    `${String(now.getHours()).padStart(2, 0)}:${String(now.getMinutes()).padStart(2, 0)}:${String(now.getSeconds()).padStart(2, 0)}\n`

  const productText = shoppingCartToHex(items);

  const encoder = new TextEncoder();

  escCommands.push(initialize, lineSpacing(100), 
    encoder.encode(datetimeLabel), move(0xE0, 0x01), encoder.encode(receiptText), 
    encoder.encode(datetimeText),
    longLine(48)); 

  escCommands = escCommands.concat(productText);
  
  escCommands.push(justify(1), encoder.encode(`\n\nVielen Dank!\n${firstname} ${lastname}\n`), feed(3), cut);

  // fuse every uint8array 
  const length = escCommands.reduce((sumLength, array) => {
    return sumLength + array.byteLength;
  }, 0);

  const data = new Uint8Array(length);

  let offset = 0;
  for (const array of escCommands) {
    data.set(array, offset);
    offset += array.byteLength;
  }
  
  return data;
}

function savePrinterSettings() {
  if(printerDevice.value === undefined){
    localStorage.removeItem("webshop-printer-store");
  } else {
    localStorage.setItem("webshop-printer-store", JSON.stringify(printerDevice.value)); 
  }
}

async function loadPrinterSettings() {
  const item = localStorage.getItem("webshop-printer-store");

  if(item === null) {
    printerDevice.value = undefined;
  } else {
    const printer = JSON.parse(item);

    // test connection
    try {
      let device = undefined;
      switch(printer.type) {
        case "USB":
          device = await navigator.usb.getDevices();

          if(device.length !== 1) {
            throw Error("Multiple usb devices connected");
          }
          printer.settings.device = device[0];

          device = await connectUsb(printer.settings.device);
          await printer.settings.device.releaseInterface(device.interface); 
          await printer.settings.device.close();
          break;
        case "Network":
          device = await connectNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort);
          device.close();
          break;
        case "Serial":
          device = await navigator.serial.getPorts();
          if(device.length !== 1) {
            throw Error("Multiple serial devices connected");
          }
          printer.settings.port = device[0];

          await device[0].open({ baudRate: 38400 });
          device[0].close();
          break;
      }
    } catch (error) {
      console.log("Connection test failed: " + error.toString());
      removePrinterSettings();
    } 

    printerDevice.value = printer;
  }
}

function removePrinterSettings() {
  if(printerDevice.value !== undefined){
    switch(printerDevice.value.type) {
      case "USB":
        navigator.usb.getDevices().then((devices) => {
          devices.forEach((d) => d.forget()); 
        });
        break;
      case "Serial":
        navigator.serial.getPorts().then((ports) => {
          ports.forEach((p) => p.forget());
        });
        break;
    }
  }
  printerDevice.value = undefined;
  savePrinterSettings();
}

/*
 * Daily Report
 */

function formatDate(date) {
  const dateString = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}` +
    `  ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  return dateString;
}

function createHeading(doc, timestamp) {
  const dateStart = new Date(timestamp * 1000);
  const dateEnd = new Date(timestamp * 1000);
  const datePrint = new Date();
  const addressObj = selectedLocationObj == undefined ? globals : selectedLocationObj;
  dateEnd.setDate(dateEnd.getDate() + 1);
  dateEnd.setTime(dateEnd.valueOf() - 1000);

  const dateStartString = formatDate(dateStart);
  const datePrintString = formatDate(datePrint);
  const dateEndString = (dateEnd.valueOf() < datePrint.valueOf()) ? formatDate(dateEnd) : datePrintString; 

  doc.setFontSize(15);
  doc.setFont("helvetica", "bold");
  doc.text("Tagesbericht", 15, 15);

  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`${addressObj.companyname}\n${addressObj.companystreet}\n${addressObj.companyzip} ${addressObj.companylocation}`, 15, 20);
  doc.text(`Start: ${dateStartString}`, 140, 20);
  doc.text(`Ende: ${dateEndString}`, 140, 25);
  doc.text(`Druck: ${datePrintString}`, 140, 35);
}

function setPageLabel(doc, pageNr, pageMax) {
  doc.setFont("helvetica", "normal");
  doc.setPage(pageNr);
  doc.text(`Seite: ${pageNr} von ${pageMax}`, 140, 40);
}

function getPageBase() {
  return 50;
}

/**
  * elements is of form [{ commands(doc, page, base), length }]
**/

function insertSegment(doc, timestamp, page, maxPages, base, limit, elements) {
  let currentPage = page;
  let currentOffset = base;

  for(const element of elements){
    if(currentOffset + element.length > limit) {
      currentOffset = getPageBase(); 
      currentPage++;
      if(currentPage > maxPages) {
        doc.addPage();
        doc.setPage(currentPage);
        createHeading(doc, timestamp);
      } else {
        doc.setPage(currentPage);
      }
    }
    const ret = element.commands(doc, currentPage, currentOffset); 
    currentOffset += ret.offset;
  } 

  return { page: currentPage, offset: currentOffset };
}

function getProductListPdf(title, itemList, itemMap) {

  const formatPrice = (value) => { 
    let val = (value/1).toFixed(2).replace('.', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  };

  const head = (doc, b) => {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(13);
    doc.text(title, 15, b);
    doc.line(15, b + 3, 195, b + 3);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Anz.", 15, b + 8);
    doc.text("Artikel", 35, b + 8);
    doc.text("MWST.", 145, b + 8)
    doc.text("Umsatz", 180, b + 8);
    doc.line(15, b + 10, 195, b + 10);

    return { offset: 15 };
  };

  const tail = (doc, b, sum, vatSum) => {
    doc.line(15, b, 195, b);
    doc.setFont("helvetica", "bold");
    doc.text("Gesamtverkauf Brutto", 35, b + 6);
    doc.text(`${formatPrice(sum)}`, 194, b + 6, {align: "right"});

    //VAT
    doc.text("enthaltene Mehrwertsteuer", 35, b + 12);
    doc.text(`${formatPrice(vatSum)}`, 194, b + 12, {align: "right"});
    doc.setFont("helvetica", "normal");

    let vatOffset = b + 17;
    vatMap.forEach((val, vatId) => {
      const vat = lookUpVat(vatId);
      doc.text(`davon ${formatPrice(val.price)} MwSt. ${vat.name} - ${vat.nameDetail} = ${vat.valueTax}%`, 40, vatOffset);
      doc.text(`${formatPrice(val.vat)}`, 194, vatOffset, { align: "right" });
      vatOffset += 5;
    });

    return { offset: vatOffset - b };
  };

  const product = (doc, b, product, amount, vat, sum = undefined) => {

    const price = (sum == undefined) ? amount * Number(product.price) : sum;

    doc.text(`${amount}`, 22, b, { align: "right" });
    doc.text(`${product.subject}`, 35, b);
    doc.text(`${vat}`, 145, b);
    doc.text(`${formatPrice(price)}`, 194, b, { align: "right" });
    
    return { offset: 5 };
  };

  let elements = [];
  elements.push({ commands: (d, _, b) => head(d, b), length: 15 });

  let sum = 0;
  let vatSum = 0;
  let vatMap = new Map();
  itemList.forEach((p) => {
    const vat = lookUpVat(itemMap.get(p.product.id).Vat); 
    const totalPrice = (p.sum == undefined) ? p.product.price * p.amount : p.sum;
    const totalVat = (totalPrice - (totalPrice / ((100 + vat.valueTax) / 100)));

    elements.push({ commands: (d, _, b) => product(d, b, p.product, p.amount, vat.name, p.sum), length: 5});
    sum += totalPrice;
    vatSum += totalVat;
    
    if(!vatMap.has(vat.id)) {
      vatMap.set(vat.id, { price: 0, vat: 0 }); 
    }
    let v = vatMap.get(vat.id);
    v.price += totalPrice;
    v.vat += totalVat;
  });  

  vatSum = vatSum.toFixed(2);
  elements.push({ commands: (d, _, b) => tail(d, b, sum, vatSum), length: 15 });
   
  return elements;
}

function getIncomelistingPdf(title, incomeList) {
  const formatPrice = (value) => { 
    let val = (value/1).toFixed(2).replace('.', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  };

  let sum = 0.0;
  incomeList.forEach((v) => { 
    sum += Number(v);
  });

  const commands = (doc, b) => {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(title, 15, b);
    doc.text(`${formatPrice(sum)}`, 194, b, { align: "right" });
    doc.line(15, b + 3, 195, b + 3);

    doc.setFont("helvetica", "normal");

    let offset = b + 8; 
    incomeList.forEach((v, k) => {
      if(v <= 0.0) {
        return;
      }

      let text = "Bar";
      switch(k) {
        case "card":
          text = "EC";
          break;
        case "credit":
        case "vend":
          text = "Guthaben (eingelöst)"
          break;
      }
      doc.text(text, 15, offset);
      doc.text(`${formatPrice(v)}`, 194, offset, { align: "right" });
      offset += 5;
    });
    
    return { offset: offset - b };
  };
  
  return [{ commands: (d, _, b) => commands(d, b), length: 25 }];
} 

function processCartList(cartList) {
  const depleteCredit = (c, i, m) => {
    const rest = (c - i.price); 
    const redeemed = (rest > 0.0) ? i.price : c;
    m.set("credit", Number(m.get("credit")) + redeemed);
    return redeemed; 
  };

  let products = new Map();
  let memberships = new Map();
  let productIncome = new Map([["cash", 0.0], ["card", 0.0], ["credit", 0.0], ["vend", 0.0]]);
  let membershipIncome = new Map([["cash", 0.0], ["card", 0.0], ["credit", 0.0], ["vend", 0.0]]); 
  for(const cart of cartList) {
    let credit = cart.redeem;
    const cartProducts = JSON.parse(cart.products);
    for(const item of cartProducts) {
      if(item.id === 0 && !productMap.has(item.id)) {
        productMap.set(item.id, item);
      }

      if(productMap.has(item.id)) {
        const currentAmount = (products.has(item.id)) ? products.get(item.id).amount : 0;
        products.set(item.id, { product: item, amount: currentAmount + 1 });

        const redeemed = depleteCredit(credit, item, productIncome);
        credit = credit - redeemed; 
        credit = (credit < 0) ? 0 : credit;

        productIncome.set(cart.method, Number(productIncome.get(cart.method)) + Number(item.price) - Number(redeemed));
      } else if(aboMap.has(item.id)) {
        const currentAmount = (memberships.has(item.id)) ? memberships.get(item.id).amount : 0;
        const currentSum = (memberships.has(item.id)) ? memberships.get(item.id).sum : 0;
        memberships.set(item.id, { product: item, amount: currentAmount + 1, sum: currentSum + parseFloat(item.price)});

        const redeemed = depleteCredit(credit, item, membershipIncome);
        credit = credit - redeemed; 
        credit = (credit < 0) ? 0 : credit;

        membershipIncome.set(cart.method, Number(membershipIncome.get(cart.method)) + Number(item.price) - Number(redeemed));
      }
    }
  }
  
  return { products: { list: products, income: productIncome }, memberships: { list: memberships, income: membershipIncome }};
}

function createDailyReport(cartList, timestamp) {
  const cart = processCartList(cartList);
  const limit = 275;
  let doc = new jsPDF();
  let pageCount = 1;

  // create heading with company info.
  createHeading(doc, timestamp);

  const products = getProductListPdf("Umsatz Verkauf", cart.products.list, productMap);
  const retProd = insertSegment(doc, timestamp, pageCount, pageCount, getPageBase(), limit, products); 
  pageCount = retProd.page;

  const prodIncome = getIncomelistingPdf("Einnahmen Verkauf", cart.products.income);
  const retIncomeProd = insertSegment(doc, timestamp, pageCount, pageCount, retProd.offset + 5, limit, prodIncome);
  pageCount = retIncomeProd.page;

  const memberships = getProductListPdf("Beitragszahlungen", cart.memberships.list, aboMap);
  const retMem = insertSegment(doc, timestamp, pageCount, pageCount, retIncomeProd.offset + 10, limit, memberships);
  pageCount = retMem.page;

  const memIncome = getIncomelistingPdf("Einnahmen Beitragszahlungen", cart.memberships.income);
  const retIncomeMem = insertSegment(doc, timestamp, pageCount, pageCount, retMem.offset + 10, limit, memIncome);
  pageCount = retIncomeMem.page;

  cart.products.income.forEach((v, k) => cart.memberships.income.set(k, cart.memberships.income.get(k) + v));
  const totalIncome = getIncomelistingPdf("Auswertung Einnahmen (effektiv)", cart.memberships.income);
  const retIncomeTotal = insertSegment(doc, timestamp, pageCount, pageCount, retIncomeMem.offset + 10, limit, totalIncome);
  pageCount = retIncomeTotal.page;

  // set page numbers.
  for(let i = 1; i <= pageCount; i++) {
    setPageLabel(doc, i, pageCount); 
  }
  //finish by saving and serving document.
  doc.save(`Tagesabschluss-${new Date(timestamp * 1000).toLocaleDateString()}.pdf`);
}

function lookUpVat(id) {
  return (id == null) ? vatList[0] : vatList.filter((vat) => vat.id === id)[0];
}


export default {
  async mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-shopsale", "/#/dashboard");
      window.location.href = redirecturl;
    }

    this.tabs = new Foundation.Tabs($('#tabs'), {
      // These options can be declarative using the data attributes
      matchHeight: false
    });

    this.accordionCredit = new Foundation.Accordion($('#accordionCredit'), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiExpand: false,
      allowAllClosed: true,
    });

    fetch(apihost+"/vue/location/listobj/")
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        locationList.value = result;
        selectedLocation.value = localStorage.getItem("shop-location") == undefined ? locationList.value[0].id : Number(localStorage.getItem("shop-location"));
        selectedLocationObj = locationList.value.find((e) => e.id == selectedLocation.value);
        locationLoaded.value = true;
      })
      .catch(() => { throw 'Network error'; });

    fetch(apihost+"/vue/vat/list/")
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        vatList = result;
        loaded[0] = true;
      })
      .catch(() => { throw 'Network error'; });

    
    fetch(apihost+'/'+currentUser.Language+'/vue/shop/sale/?case=frontdesk')
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);

      this.shopItems = data.Shoplist;
      this.aboItems = data.Abolist;
      this.customerItems = data.Customerlist;

      for(const shopItem of this.shopItems){
        productMap.set(shopItem.id, shopItem);
      }

      for(const aboItem of this.aboItems){
        aboMap.set(aboItem.id, aboItem);
      }

      for(const customerItem of this.customerItems){
        customerMap.set(customerItem.id, customerItem); 
      }
      customerMap.set(0, guestCustomer);
      loaded[1] = true;
    })
    .catch(() => { throw 'Network error' });

    fetch(`${apihost}/${currentUser.Language}/vue/invoice/list/`)
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        for(const invoice of result){
          if(invoice.Customerid == "") {
            continue;
          }

          if(!invoiceMap.has(invoice.Customerid)){
            invoiceMap.set(invoice.Customerid, []);
          }

          if(invoice.Aboid == "" || invoice.arrRates == "" || invoice.AboSubject == ""){
            continue;
          }

          let oldInvoiceArr = invoiceMap.get(invoice.Customerid);
          const rates = invoice.arrRates.filter((rate) => rate.Valuta === "");

          if(rates.length == 0){
            continue;
          }

          oldInvoiceArr.push({ id: invoice.id, rates: rates, aboId: invoice.Aboid, aboSubject: invoice.AboSubject });
          invoiceMap.set(invoice.Customerid, oldInvoiceArr);
        } 
        loaded[2] = true;
      })
      .catch(() => { throw 'Network error'; });

    fetch(apihost+"/vue/global/getglobal/?companyname&companyzip&companystreet&companylocation")
      .then(handleErrors)
      .then(response => response.json())
      .then(result => {
        globals = result;
        loaded[3] = true;
      })
      .catch(() => { throw 'Network error'; });
    
    this.timestamp = Date.now();

    loadPrinterSettings().then();
    
    const loop = () => {
      setTimeout(() => {
        const isLoaded = loaded.reduce((acc, curr) => acc && curr, true);
        if(!isLoaded){
          loop();
        } else {
          this.isDataLoaded = true;
        } 
      }, 100);
    };

    loop();
  },
  components: {

      DxList,
      DxTileView,
      DxNumberBox,
      DxSelectBox,
      DxLoadIndicator,
      DxDateBox,
      DxPopup,
      DxDataGrid,
      DxPaging,
      DxColumn,
      DxScrolling,
      DxMasterDetail,
      DxTagBox,

      Vue3Barcode,

    },
  data() {
    return {
      apihost,

      title: currentUser.Translation.vueappNavMemberSale,

      timestamp,

      currentUser,

      shopItems,
      aboItems,
      customerItems,

      selectedItems,
      selectedSum,

      selectedCustomer,
      selectedCredit,
      selectedRates,
      
      printerDevice,
      selectedType,
      ipAddress,
      tcpPort,

      creditThumbnail,
      creditRedeemValue,

      paymentMethod,
      paymentInProcess,

      journalDate,
      oldTransactionDate,

      receiptPopupVisible,
      deleteTransactionVisible,
      deleteTransactionId,

      shoppingcartListDataSource,
      oldCartGridObj,

      invoiceMap,
      aboMap,

      isDataLoaded,

      rateGridObj,
      rateMasterData,
      
      filterArray,
      locationList,
      selectedLocation,
      locationLoaded,
    };
  },

  computed: {
  },

  methods: {
    selectshopitem(id,price,subject,sku,pic) {
      const arrayShop = {
        id: id,
        price: price,
        subject: subject,
        sku: sku,
        pic: pic,
      };

      this.selectedItems.push(arrayShop);

      let sum = 0;
      for (let i = 0; i < selectedItems.length; i++) {
        sum = sum+selectedItems[i].price;
      }
      this.selectedSum = sum;

     },

     removeshopitem(index) {
      this.selectedItems.splice(index,1);

      let sum = 0;
      for (let i = 0; i < selectedItems.length; i++) {
        sum = sum+selectedItems[i].price;
      }
      this.selectedSum = sum;
      this.selectedRates.delete(index);

      creditRedeemValue.value = Math.min(this.selectedSum, creditRedeemValue.value);
      document.getElementById("Redeem").value = Math.min(this.selectedSum, creditRedeemValue.value);
    },

    removeall() {
      selectedItems = [];
      selectedSum = 0;
      this.selectedItems = selectedItems;
      this.selectedSum = selectedSum;

      creditRedeemValue.value = 0;
      document.getElementById("Redeem").value = 0;
    },

    selectcustomeritem(id,firstname,lastname,rfid,credit,avatar) {
      const arrayCustomer = {
        id: id,
        firstname: firstname,
        lastname: lastname,
        rfid: rfid,
        credit: credit,
        avatar: avatar,
      };

      this.selectedCustomer = arrayCustomer;
      this.rateMasterData = invoiceMap.get(id);
      this.rateGridObj.refresh();
    },

    deletecustomeritem() {
      this.selectedCustomer = {id: 0, firstname: "Gast", lastname: "", rfid: "", credit: "", avatar: apihost+"/static/assets/img/default-avatar.png"};
    },

    setcredit(id) {
      const amountelem = document.getElementById("Amount");
      let credit = 0;
      let amount = selectedCredit.value;

      if(this.selectedCustomer.credit){
        credit = this.selectedCustomer.credit;
      }
      const sum = parseFloat(credit) + parseFloat(amount);

      fetch(apihost+'/'+currentUser.Language+'/vue/shop/sale/?action=setcredit&customerid='+id+'&amount='+amount)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        this.customerItems = data.Customerlist;
      })
      .catch(() => { throw 'Network error' });

      this.selectedCustomer = {
        id: id, 
        firstname: this.selectedCustomer.firstname, 
        lastname: this.selectedCustomer.lastname, 
        rfid: this.selectedCustomer.rfid, 
        credit: sum, 
        avatar: this.selectedCustomer.avatar
      };

      //Set Field to 0 after send
      amountelem.value = 0;

    },

    formatPrice(value) {
      //https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
      let val = (value/1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    },

    printTimestamp: function () {
      return Date.now();
    },
    
// POS JEFF
    async usbPair() {
      let device = undefined;
      try {
        device = await navigator.usb.requestDevice({ filters: [] });
      } catch (error) {
        return; //user didn't select port. do nothing
      }

      try {
        const deviceData = await connectUsb(device);
        await device.releaseInterface(deviceData.interface);
      } catch (error) {
        console.log("couldn't pair usb device");
        notify("Verbindung fehlgeschlagen.", "error", 3000);
        return;
      }
      
      printerDevice.value = { 
        type: "USB",
        settings: {
          device: device,
        },
      };

      await device.close();

      savePrinterSettings();
    },

    async serialPair() {
      let port = undefined;
      try {
        port = await navigator.serial.requestPort({ filters: [] });
      } catch (error) {
        return; //user didn't select port. do nothing
      }

      // try connecting to the port
      try {
        await port.open({ baudRate: 38400 });
      } catch (error) {
        console.log("Connection try failed: " + error.toString());
        notify("Verbindung fehlgeschlagen.", "error", 3000);
        return;
      }
      
      printerDevice.value = {
        type: "Serial",
        settings: {
          port: port,
        },
      };

      await port.close();

      savePrinterSettings();
    },

    async networkPair() {
      const host = ipAddress.join('.');

      try {
        const clientSocket = await connectNetwork(host, tcpPort, websocketPort);
        clientSocket.close();
      } catch (error) {
        console.log("Couldn't pair network: " + error.toString());
        notify("Verbindung fehlgeschlagen.", "error", 3000);
        return;
      }
      
      printerDevice.value = {
        type: "Network",
        settings: {
          host: host,
          tcpPort: tcpPort,
          websocketPort: websocketPort
        },
      };

      savePrinterSettings();
    },

    async printReceipt(items, firstname, lastname) {
      const printer = printerDevice.value;
      const data = getReceiptDataArray(items, firstname, lastname);

      receiptPopupVisible.value = false;

      if(printer === undefined) {
        notify("Es herrscht keine Verbindung mit der Kasse.", "error", 3000);
        return;
      }

      switch(printer.type) {
        case "USB": 
          await connectAndSendUSB(printer.settings.device, data);
          break;
        case "Serial":
          await connectAndSendSerial(printer.settings.port, data);
          break;
        case "Network":
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, data);
          break;
      }
    },

    async openDrawer() {
      const printer = printerDevice.value; 
      const pulse1 = new Uint8Array([0x1B, 0x70, 0x00, 0x14, 0x14]); 
      const pulse2 = new Uint8Array([0x1B, 0x70, 0x01, 0x14, 0x14]);
      
      if(printer === undefined) {
        notify("Es herrscht keine Verbindung mit der Kasse.", "error", 3000);
        return;
      }

      switch(printer.type) {
        case "USB":
          await connectAndSendUSB(printer.settings.device, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendUSB(printer.settings.device, pulse2);
          break;
        case "Serial":
          await connectAndSendSerial(printer.settings.port, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendSerial(printer.settings.port, pulse2);
          break;
        case "Network":
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, pulse2);
          break;
      }
    },

    async confirmPayment() {
      paymentInProcess.value = true;
      receiptPopupVisible.value = true;
      const customerid = `?customerId=${this.selectedCustomer.id}`;
      const method = `&method=${paymentMethod.value}`;
      const products = `&products=${encodeURIComponent(JSON.stringify(selectedItems))}`;
      const total = `&total=${this.selectedSum}`; 
      const redeem = `&redeem=${creditRedeemValue.value}`;
      const location = `&location=${selectedLocation.value}`;

      // need to asign credit if it is topped up.
      if(this.selectedCredit > 0) {
        this.setcredit(this.selectedCustomer.id);
      }

      if(creditRedeemValue.value > 0) {
        try {
          await fetch(apihost+'/'+currentUser.Language+'/vue/shop/sale/?action=setcredit&customerid='+this.selectedCustomer.id+
            '&amount='+(-creditRedeemValue.value));

          // Update Customer info in UI
          this.selectedCustomer.credit = this.selectedCustomer.credit - creditRedeemValue.value; 
        } catch (e) {
          console.log(e.toString())
          notify("Ein Fehler ist aufgetreten.", "error", 5000);
          paymentInProcess.value = false;
          return;
        }
      }

      this.selectedRates.forEach((rate) => {
        const valutaDate = new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(new Date());

        fetch(`${apihost}/vue/invoice/getinvoice/${rate.invoiceId}/saverate?rateId=${rate.rateId}&rateValuta=${valutaDate}`)
          .then((r) => r.json())
          .then((r) => {
            const invoiceArr = invoiceMap.get(this.selectedCustomer.id);
            invoiceArr.forEach((invoice, i) => {
              if(invoice.id == rate.invoiceId){
                const newRates = r.arrRates.filter((rate) => rate.Valuta === "");
                if(newRates.length == 0){
                  invoiceArr.splice(i, 1);
                } else { 
                  invoiceArr[i].rates = newRates;
                }
              }
            });
          })
          .catch(() => { throw 'Network error'; });
      });

      fetch(apihost + "/vue/shoppingcart/getshoppingcart/0/insert" + customerid + method + total + products + redeem + location)
        .then((r) => r.json())
        .then(() => { 
          notify("Zahlung erfolgreich abgeschlossen.", "success", 5000);

          if(paymentMethod.value === 'cash') {
            this.openDrawer();
          }

          let popupBarrier = setInterval(() => {
            if(!receiptPopupVisible.value) {
              clearInterval(popupBarrier);

              this.removeall();
              this.selectedCustomer = {id: 0, firstname: "Gast", lastname: "", rfid: "", credit: "", avatar: apihost+"/static/assets/img/default-avatar.png"};

              paymentMethod.value = ''; 
              paymentInProcess.value = false;
              this.oldCartGridObj.refresh();
            }
          }, 100);

        })
        .catch(() => {
          notify("Ein Fehler ist aufgetreten.", "error", 5000);
          paymentInProcess.value = false;
        });
        
    },

    redeemCredit() {
      const redeemInputObject = document.getElementById("Redeem");
      
      if(redeemInputObject.value === 0 || Number(redeemInputObject.value) <= Number(this.selectedCustomer.credit)) {
        creditRedeemValue.value = redeemInputObject.value;
      } else {
        notify("Kunde hat nicht genug Guthaben!", "error", 5000);
      }
    },

    async getDailyReport() {
      paymentInProcess.value = true;
      const dateStart = journalDate.value; 
      dateStart.setHours(0);
      dateStart.setMinutes(0);
      dateStart.setSeconds(0);
      dateStart.setMilliseconds(0);

      const dateQuery = `?date=${dateStart.getTime() / 1000}`;
      const intervalQuery = `&interval=1`;
      const locationQuery = `&location=${selectedLocation.value}`;
      try {
        const response = await fetch(apihost + "/vue/shoppingcart/list/" + dateQuery + intervalQuery + locationQuery);
        const cartList = await response.json();
        createDailyReport(cartList, dateStart.getTime() / 1000);
      } catch (e) {
        notify("Ein Fehler ist aufgetreten.", "error", 5000);
        console.log(e.toString());
      }

      paymentInProcess.value = false;
    },

    resetPrinter() {
      removePrinterSettings();
    },

    lookUpVatInfo(id) {
      const vat = lookUpVat(id);
      return `${vat.valueTax}% - ${vat.name}`;
    },

    lookUpCustomerInfo(id) {
      return customerMap.get(id); 
    },

    formatShoppingCart(products) {
      const productsArray = JSON.parse(products);
      const productsMapLocal = new Map();

      for(const product of productsArray){
        if(productsMapLocal.has(product.id)){
          const oldProduct = productsMapLocal.get(product.id);
          productsMapLocal.set(product.id, { product: oldProduct.product, amount: oldProduct.amount + 1});
        } else {
          productsMapLocal.set(product.id, { product: product, amount: 1});
        }
      }

      return productsMapLocal;
    },

    functionStatus(dateInvoice, dateValuta){

      const today = new Date().getTime();
      const invoice = new Date(dateInvoice).getTime();
      const valuta = dateValuta;
      let status = 0;

      if (today <= invoice) {
        status = 1;
      }

      if (today > invoice) {
        status = 2;
      }

      if (today > invoice ) {
        status = 3;
      }

      if(valuta){
        status = 4;
      }

      return status;
    },

    unixToString(timestamp){
      return formatDate(new Date(timestamp * 1000));
    },

    getPaymentMethodClass(method){
      switch(method){
        case "cash":
        case "card":
          return { 'fa-solid': true, 'fa-cash-register': true};
        case "vend":
          return { 'fa-solid': true, 'fa-mobile-retro': true};
        default:
          return {};
      }
    },

    async deleteTransaction(undo = false){
      if(deleteTransactionId.value != undefined){
        let succ = true;
        await fetch(`${apihost}/vue/shoppingcart/getshoppingcart/${deleteTransactionId.value}/save?deleted=${!undo}`)
        .catch(() => { 
          notify("Aktion fehlgeschlagen", "error", 3000); 
          succ = false;
        });
        if(succ){ notify(`Transaktion erfolgreich ${undo ? "gelöscht" : "wiederhergestellt"}.`, "success", 3000); }
      }
    },

    saveLocation() {
      localStorage.setItem("shop-location", selectedLocation.value);
      selectedLocationObj = locationList.value.find((e) => e.id == selectedLocation.value);
      this.oldCartGridObj.refresh();
    }

  },
  unmounted() {

  },
};
</script>

<style>

</style>
